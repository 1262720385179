import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../Styles/Navbar.css';
import { CurrentSignedInUserContext } from '../App';

const Navbar = () => {
    const [currentSignedInUser, setCurrentSignedInUser] = useContext(CurrentSignedInUserContext);
    const navigate = useNavigate();

    const handleLogout = () => {
        setCurrentSignedInUser({});
        navigate('/login');
    };

    return (
        <div className="navbar">
            {currentSignedInUser && currentSignedInUser.email && currentSignedInUser.email.length > 0 && (
                <>
                        {/* <Link to="/choose-pdf" className="navbar-item navbara-and-dropdown-items">
                            Choose a Paper
                        </Link>
                        <Link to="/users" className="navbar-item navbara-and-dropdown-items">
                            Users
                        </Link> */}
                    <div className="navbar-item navbara-and-dropdown-items" onClick={handleLogout}>
                        Logout
                    </div>
                    <div style={{ color: 'white', right: '0px', position: 'absolute' }}>
                        <b><u>User:</u></b>
                        <span style={{ color: 'lime' }}>&nbsp;&nbsp;&nbsp;{currentSignedInUser.email}&nbsp;&nbsp;&nbsp;</span>
                        {/* <b><u>Role:</u></b>
                        <span style={{ color: 'lime' }}>&nbsp;&nbsp;&nbsp;{currentSignedInUser.role}&nbsp;&nbsp;&nbsp;</span> */}
                    </div>
                </>
            )}
        </div>
    );
};

export default Navbar;
