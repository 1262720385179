import React, { useState, useContext } from 'react';
import { CurrentSignedInUserContext } from '../../App';
import { useNavigate } from 'react-router-dom';

const BACKEND_URL = process.env.REACT_APP_API_BASE_URL
const REACT_APP_LOGIN = `${BACKEND_URL}/login`;

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const [currentSignedInUser, setCurrentSignedInUser] = useContext(CurrentSignedInUserContext)

  const navigate = useNavigate();
  console.log(`inside the path login: ${JSON.stringify(currentSignedInUser)}`)
  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    if (!email || !password) {
      setError('Email and password are required');
      return;
    }

    const response = await fetch(`${REACT_APP_LOGIN}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        password
      })
    });

    const data = await response.json();
    if (response.ok) {
      setCurrentSignedInUser(data); // Set user info
      navigate('/extract-tables'); // Redirect to home
      console.log('page login return data: ', data)
    } else {
      setError(data.error) // Display error message
    }
  };


  return (

    <div className="d-flex justify-content-center align-items-center">
      <div className="col-12 col-md-6 border-light-subtle shadow-lg"  style={{width: '550px', marginTop: '200px', padding: '40px', borderRadius: '35px'}}>
            <div className="row">
              <div className="col-12">
                <div className="mb-5">
                  <h4 className="text-center">Welcome back!</h4>
                </div>
              </div>
            </div>
            <form onSubmit={handleLogin}>
              <div className="row gy-3 overflow-hidden">
                <div className="col-12">
                  <div className="form-floating mb-3">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label htmlFor="email" className="form-label">Email</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-floating mb-3">
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      id="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <label htmlFor="password" className="form-label">Password</label>
                  </div>
                </div>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <div className="col-12">
                  <div className="d-grid">
                    <button className="btn btn-dark btn-lg" type="submit">Login</button>
                  </div>
                </div>
              </div>
            </form>
            <div className="row">
              <div className="col-12">
                <div className="d-flex justify-content-center mt-4">
                  <div className="link-secondary text-decoration-none btn px-1" onClick={() => navigate("/register")}>Don't have an account? </div>
                  <div className="text-primary btn hover-pointer px-0" onClick={() => navigate("/register")}>Register Here</div>
                </div>
              </div>
        </div>
      </div>
    </div>

  );
};

export default Login;