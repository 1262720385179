import React, { useState, useContext } from 'react';
import { CurrentSignedInUserContext } from '../../App';
import { useNavigate } from 'react-router-dom';

const BACKEND_URL = process.env.REACT_APP_API_BASE_URL;
const REACT_APP_REGISTER = `${BACKEND_URL}/register`;

const Register = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const [currentSignedInUser, setCurrentSignedInUser] = useContext(CurrentSignedInUserContext)

  const navigate = useNavigate()

  const handleRegister = async () => {
    if (!name || !email || !password || !confirmPassword) {
      setError('All fields are required');
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    const response = await fetch(`${REACT_APP_REGISTER}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name,
        email,
        password,
        confirm_password: confirmPassword
      })
    });

    const data = await response.json();
    if (response.ok) {
      console.log(data.user); // Success message
      setCurrentSignedInUser(data.user);
      navigate('/extract-tables');
    } else {
      setError(data.error); // Display error message
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className="col-12 col-md-6 border-light-subtle shadow-lg" style={{ width: '550px', marginTop: '140px', padding: '40px', borderRadius: '35px' }}>
        <div className="row">
          <div className="col-12">
            <div className="mb-5">
              <h4 className="text-center">Register For an Account</h4>
            </div>
          </div>
        </div>
        <form onSubmit={(e) => {
          e.preventDefault();
          handleRegister();
        }}>
          <div className="row gy-3 overflow-hidden">
            <div className="col-12">
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  id="name"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <label htmlFor="name" className="form-label">Name</label>
              </div>
            </div>
            <div className="col-12">
              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  id="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label htmlFor="email" className="form-label">Email</label>
              </div>
            </div>
            <div className="col-12">
              <div className="form-floating mb-3">
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  id="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <label htmlFor="password" className="form-label">Password</label>
              </div>
            </div>
            <div className="col-12">
              <div className="form-floating mb-3">
                <input
                  type="password"
                  className="form-control"
                  name="ConfirmPassword"
                  id="ConfirmPassword"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <label htmlFor="ConfirmPassword" className="form-label">Confirm Password</label>
              </div>
            </div>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <div className="col-12">
              <div className="d-grid">
                <button className="btn btn-dark btn-lg">Register</button>
              </div>
            </div>
          </div>
        </form>
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-center mt-4">
              <div className="link-secondary text-decoration-none btn px-1" onClick={() => navigate("/login")}>Already have an account?</div>
              <div className="text-primary btn hover-pointer px-0" onClick={() => navigate("/login")}>Login Here</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
