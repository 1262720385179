import React, { useContext, useState } from 'react';
import Papa from 'papaparse';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { CurrentSignedInUserContext } from '../App';


const BACKEND_URL = process.env.REACT_APP_API_BASE_URL;
const REACT_APP_DOWNLOAD_FILE = `${BACKEND_URL}/download`;
const REACT_APP_EXTRACT_PDF_FILE = `${BACKEND_URL}/extract-pdf`;
const REACT_APP_DOWNLOAD_PNG_FILE = `${BACKEND_URL}/download-png`;

const FileTableComponent = () => {
    const [csvTables, setCsvTables] = useState([]);
    const [pngImages, setPngImages] = useState([]);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [currentSignedInUser, setCurrentSignedInUser] = useContext(CurrentSignedInUserContext)

    console.log(`currentSignedInUser: ${JSON.stringify(currentSignedInUser)}`)

    const fetchCsvData = async (filePath) => {
        try {
            const response = await fetch(`${REACT_APP_DOWNLOAD_FILE}/${filePath}`);
            const blob = await response.blob();

            const reader = new FileReader();
            reader.onload = () => {
                const csvData = reader.result;
                Papa.parse(csvData, {
                    complete: (result) => {
                        setCsvTables(prevCsvTables => [
                            ...prevCsvTables,
                            { fileName: filePath.split('/').pop(), data: result.data }
                        ]);
                    },
                    header: false,
                });
            };
            reader.readAsText(blob);
        } catch (error) {
            console.error('Error fetching CSV data:', error);
        }
    };

    const fetchPngData = async (filePath) => {
        try {
            const response = await fetch(`${REACT_APP_DOWNLOAD_PNG_FILE}/${filePath}`);
            const blob = await response.blob();

            const reader = new FileReader();
            reader.onload = () => {
                setPngImages(prevPngImages => [
                    ...prevPngImages,
                    { fileName: filePath.split('/').pop(), data: reader.result }
                ]);
            };
            reader.readAsDataURL(blob);
        } catch (error) {
            console.error('Error fetching PNG data:', error);
        }
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleFileUpload = async () => {
        if (!file) {
            alert('Please select a file to upload');
            return;
        }

        setLoading(true);
        setCsvTables([]);
        setPngImages([]);

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fetch(`${REACT_APP_EXTRACT_PDF_FILE}`, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to upload file');
            }

            const result = await response.json();
            const { files } = result;

            if (files) {
                for (const fileType in files) {
                    if (files.hasOwnProperty(fileType)) {
                        for (const filePath of files[fileType]) {
                            if (fileType === 'csv') {
                                await fetchCsvData(filePath);
                            } else if (fileType === 'png') {
                                await fetchPngData(filePath);
                            }
                        }
                    }
                }
            } else {
                alert('No .csv or .png files found after extraction');
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        } finally {
            setLoading(false);
        }
    };

    const downloadCsvTables = async () => {
        const zip = new JSZip();

        csvTables.forEach((table, index) => {
            const csvContent = Papa.unparse(table.data);
            zip.file(`${table.fileName}`, csvContent);
        });

        const zipBlob = await zip.generateAsync({ type: 'blob' });
        saveAs(zipBlob, 'csv_tables.zip');
    };

    const downloadPngImages = async () => {
        const zip = new JSZip();

        pngImages.forEach((image, index) => {
            const base64Data = image.data.replace(/^data:image\/(png|jpeg);base64,/, '');
            zip.file(`${image.fileName}`, base64Data, { base64: true });
        });

        const zipBlob = await zip.generateAsync({ type: 'blob' });
        saveAs(zipBlob, 'png_images.zip');
    };

    const downloadAll = async () => {
        const zip = new JSZip();

        csvTables.forEach((table, index) => {
            const csvContent = Papa.unparse(table.data);
            zip.file(`${table.fileName}`, csvContent);
        });

        pngImages.forEach((image, index) => {
            const base64Data = image.data.replace(/^data:image\/(png|jpeg);base64,/, '');
            zip.file(`${image.fileName}`, base64Data, { base64: true });
        });

        const zipBlob = await zip.generateAsync({ type: 'blob' });
        saveAs(zipBlob, 'csv_and_png.zip');
    };

    const downloadSingleCsv = (fileName, data) => {
        const csvContent = Papa.unparse(data);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, `${fileName}`);
    };

    const downloadSinglePng = (fileName, data) => {
        const base64Data = data.replace(/^data:image\/(png|jpeg);base64,/, '');
        const blob = atob(base64Data);
        const arrayBuffer = new ArrayBuffer(blob.length);
        const view = new Uint8Array(arrayBuffer);
        for (let i = 0; i < blob.length; i++) {
            view[i] = blob.charCodeAt(i) & 0xff;
        }
        const blobFile = new Blob([arrayBuffer], { type: 'image/png' });
        saveAs(blobFile, `${fileName}`);
    };


    return (
        <div>

            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                <input
                    style={{
                        height: '38px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        padding: '4px 6px',
                        fontSize: '14px',
                        cursor: 'pointer',
                        backgroundColor: 'blue',
                        color: 'white', // text color
                        WebkitAppearance: 'button', // for WebKit browsers
                        MozAppearance: 'button' // for Firefox
                    }}
                    type="file" accept="application/pdf" onChange={handleFileChange} />
                <button className="btn btn-primary" onClick={handleFileUpload}>Upload PDF and Extract</button>
            </div>

            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                {csvTables.length > 0 && (
                    <button className="btn btn-success" style={{ marginRight: '20px' }} onClick={downloadCsvTables}>Download All CSV Tables</button>
                )}
                {pngImages.length > 0 && (
                    <button className="btn btn-danger" style={{ marginRight: '20px' }} onClick={downloadPngImages}>Download All PNG Images</button>
                )}
                {csvTables.length > 0 && pngImages.length > 0 && (
                    <button className="btn btn-dark" onClick={downloadAll}>Download All CSV Tables and PNG Images</button>
                )}
            </div>

            {loading ? (
                <h1 style={{ color: 'green', display: 'flex', justifyContent: 'center', marginTop: '50px' }}>Extracting...</h1>
            ) : (
                <div style={{ marginTop: '20px', marginLeft: '20px', marginRight: '20px' }}>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <tbody>
                            {csvTables.map((tableData, index) => (
                                <tr key={`file-row-${index}`}>
                                    <td style={{ verticalAlign: 'top', border: '5px solid blue' }}>
                                        <div style={{ marginTop: '20px', marginBottom: '40px', marginLeft: '13px', marginRight: '13px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <h3>{tableData.fileName}</h3>
                                                <button className="btn btn-primary" onClick={() => downloadSingleCsv(tableData.fileName, tableData.data)}>Download CSV</button>
                                            </div>
                                            <table border="1" style={{ width: '100%', borderCollapse: 'collapse' }}>
                                                <thead>
                                                    <tr>
                                                        {tableData.data.length > 0 && tableData.data[0].map((cell, cellIndex) => (
                                                            <th key={`csv-th-${cellIndex}`} style={{ padding: '8px', backgroundColor: '#f2f2f2' }}>{cell}</th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tableData.data.slice(1).map((row, rowIndex) => (
                                                        <tr key={`csv-tr-${rowIndex}`} style={{ backgroundColor: rowIndex % 2 === 0 ? 'white' : 'lightyellow' }}>
                                                            {/* {row.map((cell, cellIndex) => (
                                                                <td key={`csv-td-${cellIndex}`} style={{ padding: '8px', border: '1px solid #ddd' }}>{cell}</td>
                                                            ))} */}

                                                            {/* Render all cells except the last one */}
                                                            {row.slice(0, -1).map((cell, cellIndex) => (
                                                                <td key={`csv-td-${cellIndex}`} style={{ padding: '8px', border: '1px solid #ddd' }}>{cell}</td>
                                                            ))}
                                                            {/* Check the last cell in the row */}
                                                            {row[row.length - 1].trim().length > 0 && (
                                                                <td key={`csv-td-${row.length - 1}`} style={{ padding: '8px', border: '1px solid #ddd' }}>{row[row.length - 1]}</td>
                                                            )}

                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                    </td>

                                    {pngImages[index] && (
                                        <td style={{ verticalAlign: 'top', border: '5px solid blue' }}>
                                            <div style={{ marginTop: '20px', marginBottom: '40px', marginLeft: '8px', marginRight: '8px' }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <h3>{pngImages[index].fileName}</h3>
                                                    <button className="btn btn-primary" onClick={() => downloadSinglePng(pngImages[index].fileName, pngImages[index].data)}>Download PNG</button>
                                                </div>
                                                <img src={pngImages[index].data} alt={`png-${index}`} style={{ maxWidth: '100%', height: 'auto' }} />
                                            </div>
                                        </td>
                                    )}

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default FileTableComponent;












// import React, { useState } from 'react';
// import Papa from 'papaparse';

// const BACKEND_URL = process.env.REACT_APP_API_BASE_URL
// const REACT_APP_DOWNLOAD_FILE = `${BACKEND_URL}/download`;
// const REACT_APP_EXTRACT_PDF_FILE = `${BACKEND_URL}/extract-pdf`;

// const CSVTableComponent = () => {
//     const [tablesData, setTablesData] = useState([]);
//     const [file, setFile] = useState(null);
//     const [loading, setLoading] = useState(false);

//     const fetchData = async (filePath) => {
//         try {
//             const response = await fetch(`${REACT_APP_DOWNLOAD_FILE}/${filePath}`);

//             if (!response.ok) {
//                 throw new Error(`Failed to fetch file: ${response.status} ${response.statusText}`);
//             }

//             const blob = await response.blob();

//             const reader = new FileReader();
//             reader.onload = () => {
//                 const csvData = reader.result;
//                 Papa.parse(csvData, {
//                     complete: (result) => {
//                         // Check if the result is valid CSV data
//                         if (Array.isArray(result.data)) {
//                             setTablesData(prevTablesData => [...prevTablesData, result.data]);
//                         } else {
//                             console.warn('Received invalid data:', result);
//                         }
//                     },
//                     header: false,
//                 });
//             };
//             reader.readAsText(blob);
//         } catch (error) {
//             console.error('Error fetching data:', error);
//             // Handle error state or display appropriate message
//         }
//     };


//     const handleFileChange = (event) => {
//         setFile(event.target.files[0]);
//     };

//     const handleFileUpload = async () => {
//         if (!file) {
//             alert('Please select a file to upload');
//             return;
//         }

//         setLoading(true);
//         setTablesData([]); // Clear previous data

//         const formData = new FormData();
//         formData.append('file', file);

//         try {
//             const response = await fetch(`${REACT_APP_EXTRACT_PDF_FILE}`, {
//                 method: 'POST',
//                 body: formData,
//             });

//             if (!response.ok) {
//                 throw new Error('Failed to upload file');
//             }

//             const result = await response.json();
//             const { files } = result;

//             if (files && files.length > 0) {
//                 for (const filePath of files) {
//                     await fetchData(filePath);
//                 }
//             } else {
//                 alert('No .csv files found after extraction');
//             }
//         } catch (error) {
//             console.error('Error uploading file:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     return (
//         <div>
//             <input type="file" accept="application/pdf" onChange={handleFileChange} />
//             <button onClick={handleFileUpload}>Upload PDF and Extract</button>
//             {loading ? (
//                 <div>Loading...</div>
//             ) : (
//                 tablesData.map((tableData, tableIndex) => (
//                     <div key={tableIndex} style={{ marginTop: '20px', overflowX: 'auto' }}>
//                         <table border="1" style={{ width: '100%', borderCollapse: 'collapse' }}>
//                             <thead>
//                                 <tr>
//                                     {tableData.length > 0 && tableData[0].map((cell, index) => (
//                                         <th key={index} style={{ padding: '8px', backgroundColor: '#f2f2f2' }}>{cell}</th>
//                                     ))}
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {tableData.slice(1).map((row, rowIndex) => (
//                                     <tr key={rowIndex} style={{ backgroundColor: rowIndex % 2 === 0 ? 'white' : 'lightyellow' }}>
//                                         {row.map((cell, cellIndex) => (
//                                             <td key={cellIndex} style={{ padding: '8px', border: '1px solid #ddd' }}>{cell}</td>
//                                         ))}
//                                     </tr>
//                                 ))}
//                             </tbody>
//                         </table>
//                     </div>
//                 ))
//             )}
//         </div>
//     );
// };

// export default CSVTableComponent;







// import React, { useState } from 'react';
// import * as XLSX from 'xlsx';

// const BACKEND_URL = process.env.REACT_APP_API_BASE_URL
// const REACT_APP_DOWNLOAD_FILE = `${BACKEND_URL}/download`;
// const REACT_APP_EXTRACT_PDF_FILE = `${BACKEND_URL}/extract-pdf`;

// const XLSXTableComponent = () => {
//     const [tablesData, setTablesData] = useState([]);
//     const [file, setFile] = useState(null);
//     const [loading, setLoading] = useState(false);

//     const fetchData = async (filePath) => {
//         try {
//             const response = await fetch(`${REACT_APP_DOWNLOAD_FILE}/${filePath}`);
//             const blob = await response.blob();

//             const reader = new FileReader();
//             reader.onload = () => {
//                 const data = reader.result;
//                 const workbook = XLSX.read(data, { type: 'array' });
//                 const sheetName = workbook.SheetNames[0];
//                 const worksheet = workbook.Sheets[sheetName];
//                 const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: '' });

//                 setTablesData(prevTablesData => [...prevTablesData, jsonData]);
//             };
//             reader.readAsArrayBuffer(blob);
//         } catch (error) {
//             console.error('Error fetching data:', error);
//         }
//     };

//     const handleFileChange = (event) => {
//         setFile(event.target.files[0]);
//     };

//     const handleFileUpload = async () => {
//         if (!file) {
//             alert('Please select a file to upload');
//             return;
//         }

//         setLoading(true);
//         setTablesData([]); // Clear previous data

//         const formData = new FormData();
//         formData.append('file', file);

//         try {
//             const response = await fetch(`${REACT_APP_EXTRACT_PDF_FILE}`, {
//                 method: 'POST',
//                 body: formData,
//             });

//             if (!response.ok) {
//                 throw new Error('Failed to upload file');
//             }

//             const result = await response.json();
//             const { files } = result;

//             if (files && files.length > 0) {
//                 for (const filePath of files) {
//                     await fetchData(filePath);
//                 }
//             } else {
//                 alert('No .xlsx files found after extraction');
//             }
//         } catch (error) {
//             console.error('Error uploading file:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     return (
//         <div>
//             <input type="file" accept="application/pdf" onChange={handleFileChange} />
//             <button onClick={handleFileUpload}>Upload PDF and Extract</button>
//             {loading ? (
//                 <div>Loading...</div>
//             ) : (
//                 tablesData.map((tableData, tableIndex) => (
//                     <div key={tableIndex} style={{ marginTop: '20px', overflowX: 'auto' }}>
//                         <table border="1" style={{ width: '100%', borderCollapse: 'collapse' }}>
//                             <thead>
//                                 <tr>
//                                     {tableData.length > 0 && tableData[0].map((cell, index) => (
//                                         <th key={index} style={{ padding: '8px', backgroundColor: '#f2f2f2' }}>{cell}</th>
//                                     ))}
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {tableData.slice(1).map((row, rowIndex) => (
//                                     <tr key={rowIndex} style={{ backgroundColor: rowIndex % 2 === 0 ? 'white' : 'lightyellow' }}>
//                                         {row.map((cell, cellIndex) => (
//                                             <td key={cellIndex} style={{ padding: '8px', border: '1px solid #ddd' }}>{cell}</td>
//                                         ))}
//                                     </tr>
//                                 ))}
//                             </tbody>

//                         </table>
//                     </div>
//                 ))
//             )}
//         </div>
//     );
// };

// export default XLSXTableComponent;
