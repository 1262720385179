import React, { useState, createContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './Components/Accounts/Login.js';
import Register from './Components/Accounts/Register.js'
import Navbar from './Components/Navbar';
import './Styles/App.css';
import FileTableComponent from './Components/FileTableComponent.js';

export const CurrentSignedInUserContext = createContext();

function App() {
  const [currentSignedInUser, setCurrentSignedInUser] = useState({});

  useEffect(() => {
    console.log(`Current Signed In User: ${JSON.stringify(currentSignedInUser)}`)
  }, [currentSignedInUser])

  return (
    <CurrentSignedInUserContext.Provider value={[currentSignedInUser, setCurrentSignedInUser]}>
      <Router>
        {Object.keys(currentSignedInUser).length === 0 ? (

          //if user isnt signed in or registered
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="*" element={<Navigate to="/login" />} />

          </Routes>
        ) : (

          //if user is signed in
          <>
            <Navbar />
            <Routes>
              <Route path='/extract-tables' element={<FileTableComponent />} />
            </Routes>
          </>
        )}
      </Router>
    </CurrentSignedInUserContext.Provider>);
};

export default App;